<template>

    <div id="menu-left-cg" class="vs-sidebar vs-sidebar-primary">
        <div class="vs-sidebar--items">
            <div slot="header" class="header-sidebar flex items-end menulist">
                <router-link tag="div" class="vx-logo cursor-pointer flex items-center" to="/">
                    <img src="@/assets/images/ehpad/logo.png" alt=""/>
                </router-link>
            </div>
            <section class="ps-container scroll-area-v-nav-menu pt-2 ps ps--theme_default ps--active-y">
                <div class="menu">
                    <a href="#h21" class="selected">1 - Informations générales</a><br />
                    <a href="#h22">1.1	Hébergeur</a><br />
                    <a href="#h23">1.2 Editeur</a><br />
                    <a href="#h24">1.3 Contacts Happytal</a><br />
                    <a href="#h25">2. Référencement des Vendeurs</a><br />
                    <a href="#h26">3.	Compte personnel du Référent</a><br />
                    <a href="#h26b">3.1	Création du compte</a><br />
                    <a href="#h27">3.2 Données de connexion</a><br />
                    <a href="#h28">3.3 Sous-compte Résident</a><br />
                    <a href="#h29">4.	Fonctionnement de la Plateforme Happydom</a><br />
                    <a href="#h29b">4.1	Produits et Services</a><br />
                    <a href="#h210">4.2	Processus de commande</a><br />
                    <a href="#h211">4.3	Modification ou annulation de commandes</a><br />
                    <a href="#h211b">4.3.1	Commandes récurrentes</a><br />
                    <a href="#h212">4.3.2	Commandes ponctuelles</a><br />
                    <a href="#h213">4.3.3	Décès/départ/absence prolongée du Résident</a><br />
                    <a href="#h214">4.4	Processus de paiement</a><br />
                    <a href="#h215">5. Accès de l’Etablissement à certaines informations</a><br />
                    <a href="#h216">6. Propriété intellectuelle</a><br />
                    <a href="#h217">7. Cookies</a><br />
                    <a href="#h218">8. Protection des données personnelles</a><br />
                    <a href="#h219">9. Responsabilité</a><br />
                    <a href="#h220">10.	Accessibilité à la Plateforme Happydom</a><br />
                    <a href="#h221">11.	Modification des CGU</a><br />
                    <a href="#h222">12.	Résiliation</a><br />
                    <a href="#h223">13.	Effets de la résiliation</a><br />
                    <a href="#h224">14.	Droit applicable et compétence juridictionnelle</a><br />
                </div>
            </section>

        </div>
    </div>
</template>


<script>
    export default {
        name: 'v-nav-menu-cg',
        components: {},
        props: {},
        data: () => ({}),
        computed: {},
        watch: {},
        methods: {},
        mounted() {
        }
    }

</script>


<style lang="scss">
    @import "@/assets/scss/vuexy/components/verticalNavMenu.scss";
#menu-left-cg {
    .vx-logo {
        padding: 0 30px 0 0;
    }

    .item-mini-menu {
        font-size: 0.8em !important;
        margin-left: 3px;
        margin-top: 1px;
    }

    .menu a {
        margin-top: 4px;
        display: inline-block;
        color: #2C2C2C;
        font-size: 11px;
        text-transform: uppercase;
        &.selected {
            color:rgba(var(--vs-primary), 1);
        }
    }

    .menulist {
        flex-direction: column;
    }
    .ps-container {
        width: 80%;
        margin: 10px auto 0 auto;
    }
}
</style>
