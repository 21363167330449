<template>
    <div id="cgu">
        <v-nav-menu-cgu/>
        <div class="content-area-reduced" id="content-area">
            <the-navbar-vertical-public :theTitle="title"/>
            <div class="vx-col w-4/5 sm:tw-w-4/5 md:tw-w-3/5 lg:tw-w-3/4 xl:tw-w-4/5 mx-auto globalelement">
                <div class="vx-col tw-w-full mt-12 md:tw-mt-0 divscroll">
                    <h2 id="h21">1 - Informations générales</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le présent site internet, accessible à l'adresse « <a href="https://www.happydom.fr"
                                                                              target="_blank">https://www.happydom.fr</a>
                        » (la « <b>Plateforme Happydom</b> » ou
                        la « <b>Plateforme</b> ») repose sur une solution SaaS (ou logiciel en tant que service)
                        proposée par la société
                        Happytal, société par action simplifiée (SAS) au capital social de 13.198 euros, dont le siège
                        social est situé au
                        59-61 bis rue Pernety, 75014 Paris, inscrite au registre du Commerce et des Sociétés de Nanterre
                        sous l>
                        numéro 790 294 615 (« <b>Happytal</b> »).<br/><br/>

                        La Plateforme Happydom a vocation à mettre en relation, d’une part, Happytal ou des vendeurs et
                        prestataires
                        de services indépendants référencés par cette dernière (« <b>Vendeurs</b> ») et, d’autre part,
                        un tuteur ou proche
                        -aidant (tel que défini par l'article L. 113-1-3 du Code de l’action sociale et des familles) («
                        <b>Référent</b> ») d’un
                        résident (« <b>Résident</b> ») séjournant au sein d’un établissement d'accueil pour personnes
                        âgées partenaire de
                        Happytal (« <b>Etablissement</b> »), afin de permettre au Référent d’acheter des produits ou
                        services (les «
                        <b>Produits</b> » ou « <b>Services</b> ») destinés à être fournis au Résident directement dans
                        son Etablissement de résidence.<br/><br/>

                        Pour utiliser les services de la Plateforme Happydom, les Référents doivent lire attentivement
                        et accepter les
                        termes des présentes conditions générales d'utilisation (les « <b>CGU</b> ») dans leur
                        intégralité, sans restrictions ni
                        réserves. En cas de non-respect des présentes CGU, Happytal se réserve le droit d'interrompre de
                        manière
                        temporaire ou définitive l'accès à la Plateforme Happydom.<br/><br/>

                        Le Référent peut consulter les présentes CGU à tout moment dans la rubrique « <a
                            href="https://www.happydom.fr/cgu" target="_blank">https://www.happydom.fr/cgu</a> »
                        de la Plateforme Happydom.<br/><br/>

                        Dans l’hypothèse où l’une des dispositions des CGU serait, pour quelque raison que ce soit,
                        déclarée nulle ou
                        non écrite, cela ne devra pas affecter la validé de leurs autres dispositions.
                    </div>
                    <h2 id="h22">1.1 Hébergeur</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        La Plateforme Happydom est hébergée par : Happytal, société par action simplifiée (SAS) au
                        capital social de
                        13.198 euros, dont le siège social est situé au 59-61 bis rue Pernety, 75014 Paris, inscrite au
                        registre du
                        Commerce et des Sociétés de Nanterre sous le numéro 790 294 615.<br/><br/>

                        Concernant les ventes réalisées par les Vendeurs tiers, indépendants de Happytal, cette dernière
                        aura un rôle
                        limité à l’hébergement de contenus au sens de l’article 6 I 2) de la loi du 21 juin 2004 pour la
                        confiance en
                        l’économie numérique (la « <b>LCEN</b> »). Dans cette hypothèse, il est rappelé qu'en aucun cas,
                        Happytal ne peut
                        être considérée comme le vendeur des Produits et/ou Services et que seul le Vendeur tiers est le
                        cocontractant
                        du Référent pour la vente des Produits et/ou Services.
                    </div>
                    <h2 id="h23">1.2 Editeur</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        S’agissant des ventes réalisées par un Vendeur tiers via la Plateforme Happydom, nous vous
                        remercions de
                        vous référer aux informations contenues sur la page de la Plateforme Happydom dédiée au Vendeur
                        tiers
                        concerné, et de contacter directement le Vendeur pour plus d’informations.<br/><br/>

                        Pour toutes les autres informations publiées sur la Plateforme Happydom (notamment s’agissant
                        des ventes
                        réalisées directement par Happytal) : Happytal, société par action simplifiée (SAS) au capital
                        social de 13.198
                        euros, dont le siège social est situé au 59-61 bis rue Pernety, 75014 Paris, inscrite au
                        registre du Commerce et
                        des Sociétés de Nanterre sous le numéro 790 294 615.<br/><br/>

                        Nom du directeur ou du codirecteur de la publication : Romain Revellat<br/><br/>

                        Nom du responsable de la rédaction : Romain Revellat
                    </div>
                    <h2 id="h24">1.3 Contacts Happytal</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Afin de contacter Happytal, les coordonnées suivantes peuvent être utilisées :<br/><br/>
                        <ul>
                            <li>Adresse email : contact@happytal.com</li>
                            <li>Téléphone : +33 1 84 17 23 73</li>
                        </ul>
                    </div>
                    <h2 id="h25">2. Référencement des Vendeurs</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal a mis en œuvre une sélection de Vendeurs présents sur la Plateforme Happydom en prenant
                        en
                        compte un certain nombre de critères par lesquels notamment : la nature et la qualité des
                        Produits et Services
                        (taux de satisfaction) adaptée aux Résidents, la localisation géographique et la disponibilité
                        des Vendeurs (en
                        particulier s’agissant des services proposés), leur fiabilité et leurs qualification (diplômes,
                        certificats, etc).<br/><br/>

                        Les Produits et Services proposés sur la Plateforme Happydom sont classées par ordre
                        alphabétique.<br/><br/>

                        Dans la mesure où Happytal n’est en aucun cas partie au contrat de vente ou de prestation de
                        services conclu
                        entre un Référent et le Vendeur à travers la Plateforme Happydom, Happytal n’est tenue à aucune
                        obligation
                        ou responsabilité résultant de ce contrat. Chaque Vendeur agit en qualité de professionnel
                        indépendant et
                        vend ses Produits et/ou Services sur la Plateforme Happydom en son nom et pour son compte
                        seul.<br/><br/>

                        Ni les termes des présentes CGU, ni l’utilisation de la Plateforme Happydom ne doivent être
                        interprétés comme
                        une quelconque communauté d’intérêts, un quelconque lien de subordination ou une quelconque
                        association,
                        ni comme conférant au Vendeur la qualité de représentant, agent ou mandataire de Happytal.
                    </div>
                    <h2 id="h26">3. Compte personnel du Référent</h2>
                    <h2 id="h26b">3.1 Création du compte</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        La création d'un compte par le Référent constitue une condition nécessaire à l'accès à
                        Plateforme Happydom et
                        à l'achat des Produits et/ou Services proposés sur cette dernière ("<b>Compte</b>"). Un Référent
                        ne peut créer qu’un
                        seul Compte.<br/><br/>

                        La création du Compte et l'utilisation de la Plateforme Happydom par les Référents est gratuite
                        (hors couts
                        éventuels de la connexion, dont le prix dépend de l’opérateur de communication électronique),
                        personnelle,
                        intransmissible, et sans obligation d'achat. Le Compte est un espace privé Extranet accessible
                        en ligne
                        personnel au Référent, mis à disposition de ce dernier par Happytal. L’accès à la Plateforme
                        Happydom par le
                        Référent n’est possible et autorisé que via son Compte, aux moyens des Données de connexion
                        définies ci-dessous.<br/><br/>

                        Lors de la création du Compte, le Référent doit renseigner les informations demandées dans le
                        formulaire de
                        création dédié, dont notamment son adresse email. Un email est envoyé par la Plateforme Happydom
                        à
                        l’adresse email communiquée pour permettre au Référent de créer on mot de passe et valider la
                        création de Compte.<br/><br/>

                        Le Référent identifie également le(s) Résident(s) au(x)quel(s) les Produits et/ou Services
                        achetés par le Référent
                        via la Plateforme Happydom seraient destinés, ainsi que l'Etablissement dans lequel il(s)
                        réside(nt). Le Compte
                        du Référent sera rattaché électroniquement à la fiche administrative du/des Résident(s) établie
                        et tenue par
                        l'Etablissement. Lorsque plusieurs Résidents sont rattachés au Compte, chacun d’eux fait l’objet
                        d’un sous-
                        compte dédié (« <b>Sous-compte Résident</b> »).<br/><br/>

                        Le Référent garantit l’exactitude et la véracité des informations fournies pour les besoins de
                        l’inscription. Il
                        s’engage à actualiser régulièrement les informations le concernant.
                    </div>
                    <h2 id="h27">3.2 Données de connexion</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        L'identifiant de connexion (adresse email renseignée par le Référent) et le mot de passe sont
                        propres au
                        Référent (« <b>Données de connexion</b> »). Les Données de connexion sont confidentielles et
                        réservées à un usage
                        personnel à l'exclusion de tout usage qui en serait fait par des tiers. Le Référent s’interdit
                        de divulguer ses
                        Données de connexion à des tiers, sous quelque forme que ce soit.<br/><br/>

                        Toute connexion au Compte du Référent au moyen de ses Données de connexion est réputée faite par
                        celui-ci.
                        L’accès à la Plateforme Happydom relève de la seule responsabilité du Référent. En aucun cas, la
                        responsabilité
                        de Happytal ne pourra être engagée en raison d’une utilisation du Compte par un tiers autorisé
                        ou non, via les
                        Données de connexion du Référent.<br/><br/>

                        Si le Référent soupçonne une utilisation frauduleuse de son Compte, ou s'il identifie un risque
                        ou a
                        connaissance de faits susceptibles de compromettre la sécurité ou la confidentialité des Données
                        de connexion
                        (perte, vol, acte frauduleux, etc.), il lui appartient de le/les notifier à Happytal par email
                        dans les plus brefs
                        délais afin que l’accès au Compte puisse être suspendu. Happytal pourra attribuer au Référent un
                        autre mot de
                        passe provisoire afin que le Référent puisse choisir un nouveau mot de passe et accéder à son
                        Compte.<br/><br/>

                        Le Référent demeure responsable de l'accès éventuel à son Compte par des tiers jusqu'à
                        l'émission par
                        Happytal d'un mot de passe provisoire. Le Référent garantit Happytal de tout préjudice et de
                        toute action et/ou
                        réclamation de tiers résultant de l'usage frauduleux de ses Données de connexion.<br/><br/>

                        En cas de perte du mot de passe, le Référent dispose d'un lien dédié « Mot de passe oublié ? ».
                        Il sera demandé
                        de saisir l'adresse email communiquée lors de la création de Compte. Un courrier électronique
                        sera envoyé au
                        Référent à l'adresse indiquée. Il sera alors demandé au Référent de cliquer sur un lien
                        d'activation afin de
                        vérifier l'adresse électronique. Le Référent sera renvoyé vers une page de la Plateforme
                        Happydom où il pourra
                        choisir un nouveau mot de passe.<br/><br/>

                        Le Référent aura notamment accès via son Compte aux informations suivantes : l'identification du
                        ou des
                        Résidents rattachés (civilité, nom, prénom, date de naissance, n° de chambre), coordonnées du
                        contact au sein
                        de l'Etablissement (adresse postale, adresse email, numéro de téléphone), les commentaires
                        formulés le cas
                        échéant par le personnel de l'Etablissement, le catalogue des Produits et/ou Services
                        disponibles, le
                        récapitulatif des commandes en cours, l'échéancier de livraison(s) de Produit(s) et date(s)
                        d'exécution de
                        Service(s), l'échéancier du paiement ainsi que l’historique des commandes livrées/exécutées et
                        les factures correspondantes.<br/><br/>

                        Dans l’hypothèse où le Référent aurait fait un usage frauduleux des Données de connexion ou
                        accèderait à son
                        Compte pour des motifs excédant les besoins liés à la mise en œuvre des présentes CGU, Happytal
                        a la faculté
                        de résilier celles-ci dans les conditions prévues par l’article 12.
                    </div>
                    <h2 id="h28">3.3 Sous-compte Résident</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le Référent peut supprimer un Sous-compte Résident à tout moment, sans frais, à partir de son
                        Compte, en
                        sélectionnant le Sous-Compte Résident concerné et en cliquant sur l'option « Supprimer le
                        Sous-compte Résident ».<br/><br/>

                        Les commandes destinées au Résident correspondant en cours mais non exécutées ou en cours
                        d’exécution
                        seront automatiquement annulées. Le paiement des commandes exécutées devra être effectué par le
                        Référent
                        conformément aux modalités prévues par l'article 4.4 ci-dessous. Happytal procèdera à la
                        suppression dans ses
                        systèmes d’information des données personnelles et de toute autre donnée afférente au Résident,
                        à
                        l’exception des informations qui doivent éventuellement être conservées par Happytal au titre de
                        ses obligations légales.
                    </div>
                    <h2 id="h29">4. Fonctionnement de la Plateforme Happydom</h2>
                    <h2 id="h29b">4.1 Produits et Services</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        La commande de Produits et/ou Services via la Plateforme Happydom n'est possible qu'en ligne.
                        Pour passer
                        une commande, le Référent doit s'identifier avec ses Données de connexion.<br/><br/>

                        La page internet de la Plateforme Happydom présente les différents Produits et/ou Services
                        référencés, ainsi
                        que leur prix. Il est possible, en « cliquant » sur chaque Produit ou Service d’accéder
                        notamment aux
                        informations suivantes :<br/><br/>

                        <ul>
                            <li>- Les caractéristiques essentielles du Produit ou du Service proposé ;</li>
                            <li>- Le prix du Produit ou du Service TTC ainsi que les frais de livraison le cas échéant
                                ;
                            </li>
                            <li>- Les modalités de paiement ;</li>
                            <li>- La date ou le délai de livraison du Produit ou d’exécution de la prestation de Service
                                ;
                            </li>
                            <li>- L’identité et les conditions générales de vente du Vendeur.</li>
                        </ul>
                        <br/>

                        En fonction de leurs localisation géographique, les Vendeurs renseignent lors de leur
                        inscription le périmètre
                        des Etablissements susceptibles de se voir proposer leurs Produits et Services. Le Référent
                        visualise ainsi, pour
                        chaque Sous-compte Résident, uniquement les Produits et Services disponibles et pouvant être
                        fournis au Résident concerné.
                    </div>
                    <h2 id="h210">4.2 Processus de commande</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le Référent reconnait et accepte que les actions qu'il effectue sur la Plateforme Happydom sont
                        effectuées en
                        son propre nom et pour son propre compte, indépendamment du fait que les Produits et/ou Services
                        soient
                        destinés au Résident.<br/><br/>

                        Le Référent ajoute dans son panier le(s) Produit(s) et/ou Service(s) qu'il souhaite commander,
                        occasionnellement ou de façon récurrente dans le cadre du plan de confort personnalisé défini
                        pour le Résident. Les frais de livraison qui seront ajoutés au prix des Produits le cas échéant
                        sont indiqués avant validation de la commande. Les commandes récurrentes peuvent être saisies
                        pour une durée totale de six (6) mois calendaires. A l'expiration de la période de validité
                        d'une commande récurrente donnée, une nouvelle commande devra être saisie par le
                        Référent.<br/><br/>

                        Le Référent vérifie le détail de sa commande et le prix total indiqué. Il peut la modifier puis
                        valide ladite
                        commande. Il reconnaît que la validation de la commande implique l'existence d'une obligation de
                        paiement.<br/><br/>

                        La page indique par défaut, comme lieu de livraison/d’exécution, le lieu de l’Etablissement du
                        Résident auquel
                        le Compte du Référent est rattaché.<br/><br/>

                        Le Référent procède au paiement de la commande, dans les conditions décrites au paragraphe 4.4
                        ci-dessous.<br/><br/>

                        Le Référent reçoit dans un délai de deux (2) jours ouvrés un email de confirmation ou de refus
                        de la
                        commande. Dans le cas où la commande est confirmée, le montant correspondant sera débité le
                        entre le 1er et
                        le 15 du mois suivant la commande.<br/><br/>

                        En cas de Produit ou Service relevant d'une commande récurrente temporairement indisponible, le
                        compte
                        bancaire associé au Compte du Référent ne sera pas débité du montant correspondant pour
                        l'échéance
                        considérée et aucun Produit ou Service de remplacement ne sera délivré au Résident, sauf accord
                        express du
                        Référent. Si le Produit ou Service concerné relève d'une commande ponctuelle, la partie de la
                        commande
                        relative à ce Produit ou Service est annulée.
                    </div>
                    <h2 id="h211">4.3 Modification ou annulation de commandes</h2>
                    <h2 id="h211b">4.3.1 Commandes récurrentes</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le Référent peut modifier ou annuler toute commande récurrente de Produit(s) et/ou Service(s).
                        Sous réserve
                        de procéder à l’annulation ou à la modification de la commande au plus tard le dernier jour du
                        mois en cours
                        (mois M), celle-ci sera effective à compter du premier jour du mois suivant (mois M+1).
                    </div>
                    <h2 id="h212">4.3.2 Commandes ponctuelles</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les conditions et modalités d'annulation des commandes ponctuelles sont régies par les
                        conditions générales
                        de vente des Vendeurs applicables au(x) Produit(s) et/ou Service(s) concernés.
                    </div>
                    <h2 id="h213">4.3.3 Décès/départ/absence prolongée du Résident</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        En cas de décès du Résident, de départ ou d’absence prolongée de celui de l’Etablissement, le
                        Référent pourra
                        annuler les commandes de Produits et/ou Services en cours mais non exécutées ou en cours
                        d’exécution,
                        destinés au Résident, sur demande envoyée au Vendeur par courrier électronique. Les commandes
                        exécutées
                        devront être réglées par le Référent conformément aux modalités prévues par l'article 4.4
                        ci-dessous.<br/><br/>

                        Si le Référent n’y a pas lui-même procédé, Happytal procèdera, dans un délai de cinq (5) jours
                        ouvrés à
                        compter de la réception par Happytal de la notification du décès, du départ ou de l’absence
                        prolongée d’un
                        Résident rattaché au Compte du Référent, à la suspension des commandes (ponctuelles ou
                        récurrentes) en
                        cours mais non exécutées ou en cours d’exécution de Produits et/ou Services destinés au Résident
                        en question.
                        Sans préjudice de ce qui précède, Happytal pourra avoir été informé du changement de situation
                        du Résident
                        par l'Etablissement et, le cas échéant, avoir d’ores et déjà procédé à la suspension des
                        commandes concernées,
                        ce que Référent reconnait et accepte.<br/><br/>

                        Happytal informera immédiatement le Référent, par email et via son Compte, de la suspension des
                        commandes, cette suspension pouvant être levée sur simple demande du Référent adressée à
                        Happytal par
                        email ou depuis son Compte.
                    </div>
                    <h2 id="h214">4.4 Processus de paiement</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Afin de confirmer la commande, le Référent devra régler le total de ses achats en ligne en
                        utilisant une des
                        options suivantes :<br/><br/>

                        <ul>
                            <li>- par prélèvement sur carte bancaire : Visa, Mastercard.</li>
                            <li>- par prélèvement automatique sur compte bancaire renseigné par le Client dans son
                                Compte.
                            </li>
                        </ul>
                        <br/>

                        Lors de la validation de la commande, le paiement s'effectue de façon sécurisée via le module de
                        paiement
                        électronique mis en place par notre partenaire Stripe.<br/><br/>

                        Le Référent accepte que des informations et documents le concernant puissent être communiquées à
                        Stripe.
                    </div>
                    <h2 id="h215">5. Accès de l’Etablissement à certaines informations</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les Produits et/ou Services sont destinés à être fournis uniquement à des Résidents séjournant
                        dans des
                        Etablissements partenaires ayant souscrit à la solution Happydom. Pour ce faire, les Produits
                        sont réceptionnés
                        directement par l’Etablissement qui organise leur réception et distribution dans les chambres
                        des Résidents.
                        L'Etablissement accueille également le prestataire de Services ayant un rendez-vous avec le
                        Résident.<br/><br/>

                        Dans ce cadre, l’Etablissement a accès aux seules informations suivantes, ce que le Référent
                        accepte expressément :<br/><br/>

                        <ul>
                            <li>- La nature, la quantité ainsi que le délai de livraison/exécution du/des Produit(s)
                                et/ou Service(s)
                                destiné(s) à chaque Résident s’agissant des commandes en cours;
                            </li>
                            <li>- Des informations individualisées par Vendeur, relatives au volume de commandes et la
                                nature des
                                Produits ou Services destinés à leurs Résidents ;
                            </li>
                            <li>- L'historique des commandes de chaque Résident : listes des Produits et Services
                                livrés/exécutés et les
                                commandes annulées. L'Etablissement n'aura pas accès au montant des commandes.
                            </li>
                        </ul>
                        <br/>

                        En outre, un service de messagerie est disponible dans la rubrique dédiée de chaque Sous-compte
                        Résident de
                        la Plateforme Happydom afin de permettre au Référent et à l’Etablissement d’échanger des
                        messages portant
                        par exemple sur les préférences du Résident ou d'éventuelles contre-indications relatives aux
                        Produits ou
                        Services commandés. Les messages échanges sur la messagerie ne sont lisibles que par le
                        Référent,
                        l’Etablissement et Happytal en tant qu’opérateur de Happydom.<br/><br/>

                        Happytal ne communique en revanche aux Etablissements aucun identifiant et/ou mot de passe
                        appartenant aux Référents.
                    </div>
                    <h2 id="h216">6. Propriété intellectuelle</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        L'architecture de la Plateforme Happydom, les marques, logos, dénominations sociales, slogans,
                        noms de
                        domaine, bases de données, logiciels, contenus et tous les autres éléments composant la
                        Plateforme
                        Happydom, sans que cette liste ne soit exhaustive (les « <b>Eléments Protégés</b> »), sont la
                        propriété exclusive de
                        Happytal. Ainsi, le Référent est informé que les présentes CGU n’emportent aucune cession de
                        droits de
                        propriété intellectuelle attachés à la Plateforme Happydom ou aux Eléments Protégés à son
                        bénéfice.<br/><br/>

                        Happytal consent simplement au Référent qui l'accepte une licence d'utilisation de la Plateforme
                        Happydom.
                        La licence concède au Référent à titre gratuit, personnel et non exclusif un droit d’accès à la
                        Plateforme
                        Happydom, exclusivement pour son usage privé et non commercial.<br/><br/>

                        Toute mise en réseau, toute rediffusion ou transmission à un tiers, sous quelque forme, même
                        partielle, sont
                        interdites. Tout Référent s’engage notamment à ne pas extraire, reproduire sur tout autre
                        support, copier, à
                        des fins commerciales ou non, tout ou partie des informations présentes sur la Plateforme
                        Happydom. Le
                        Référent s’abstient de tout agissement susceptible de porter atteinte, directement ou
                        indirectement, aux droits
                        de propriété intellectuelle de Happytal sur la Plateforme Happydom et les éléments la composant.<br/><br/>

                        Le Référent s'engage enfin à ne pas accéder ou ne pas tenter d’accéder à la Plateforme Happydom
                        par tout
                        moyen autre que son Compte, interface de la Plateforme Happydom fournie par Happytal, ainsi qu’à
                        n’entreprendre aucune activité de nature à entraver ou à perturber le fonctionnement de la
                        Plateforme
                        Happydom.
                    </div>
                    <h2 id="h217">7. Cookies</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Le terme cookie couvre l’ensemble des traceurs (petits fichiers texte, image ou logiciel), qui
                        sont placés et
                        stockés sur un ordinateur, smartphone ou tout autre appareil permettant de naviguer sur la
                        Plateforme
                        Happydom. Des informations relatives à la navigation du Référent sont stockées par les cookies,
                        pendant une
                        durée de validité limitée, afin principalement d’améliorer l’expérience utilisateur.<br/><br/>

                        Les différents types de cookies utilisés sur la Plateforme Happydom sont les suivants : [En
                        continuant à
                        naviguer sur ce site, vous acceptez l’utilisation de cookies pour (i) faciliter votre navigation
                        et gérer vos paniers
                        d’achats (Cookies Navigation), (ii) mesurer l’audience de notre Site (Cookies Audiences), (iii)
                        personnaliser le
                        contenu de nos publicités lors de votre navigation, y compris sur d’autres Sites (Cookies
                        Personnalisation) et
                        (iv) vous permettre de partager le contenu de notre site sur les réseaux sociaux (Cookies
                        Réseaux Sociaux) Pour
                        en savoir plus, et savoir comment vous opposer à ces cookies et traceurs,
                        <a href="https://www.happytal.com/fr/ppdp" target="_blank">consultez notre Politique de
                            Protection des données personnelles</a> ].<br/><br/>

                        Le Référent autorise expressément Happytal à placer des cookies sur son terminal de
                        communication
                        électronique. Le Référent dispose d’un droit d’opposition et d’accès sur les données le
                        concernant, en utilisant
                        les paramètres appropriés de son navigateur concernant les cookies, le mode navigation privée ou
                        encore le
                        paramétrage « Do Not Track » de son navigateur. Néanmoins, si le Référent s'oppose à
                        l’installation des cookies
                        ou procède à leur suppression, il reconnait que l'utilisation de la Plateforme Happydom pourra
                        se révéler
                        altérée, voire impossible, sans que cela ne puisse ouvrir un quelconque droit à indemnisation.
                    </div>
                    <h2 id="h218">8. Protection des données personnelles</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal est très soucieuse de respecter la vie privée et la confidentialité des données
                        personnelles, celle-ci
                        s'engage ainsi à traiter les données personnelles dans le respect des lois et réglementations
                        applicables, et
                        notamment la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
                        libertés (la « <b>Loi
                        Informatique et Libertés</b> »), et le Règlement (UE) 2016/679 du Parlement européen et du
                        Conseil du 27 avril
                        2016 relatif à la protection des personnes physiques à l'égard du traitement des données à
                        caractère personnel
                        et à la libre circulation de ces données (le « <b>RGPD</b> »).<br/><br/>

                        Happytal s’engage auprès des Résidents et Référents de ne pas divulguer ou vendre à des tiers
                        les données à
                        caractère personnel qui lui sont communiquées, sauf pour les besoins des ventes réalisées par le
                        Vendeur sur la
                        Plateforme Happydom.<br/><br/>

                        Les données à caractère personnel des Résidents et Référents sont traitées par Happytal pour la
                        création et la
                        sécurisation du Compte et pour permettre l’accès des Référents à la Plateforme Happydom, la
                        gestion et le
                        suivi des commandes, la fourniture des services et la livraison des produits dans
                        l’Établissement.<br/><br/>

                        Les Résidents et Référents disposent d’un droit d’accès, de rectification, d’opposition et
                        d’effacement de leurs
                        données personnelles ou de limitation à leur traitement dans les conditions prévues par le
                        Règlement général
                        sur la protection des données (RGPD) et la Loi Informatique et Libertés. Pour exercer ces
                        droits, ils peuvent
                        contacter Happytal par email : dpo@happytal.com. En cas de différend quant à l’utilisation de
                        leurs données,
                        ils disposent du droit d’introduire une réclamation auprès de la Commission Nationale de
                        l’Informatique et des Libertés.<br/><br/>

                        Les caractéristiques du traitement des données à caractère personnel sont détaillées dans la
                        politique de
                        protection des données de Happytal accessible à tout moment sur la Plateforme Happydom et sur le
                        site internet d’Happytal.
                    </div>
                    <h2 id="h219">9. Responsabilité</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        La responsabilité de Happytal ne peut être engagée que pour des faits qui lui seraient
                        directement imputables
                        et qui causeraient au Référent un préjudice directement lié à ces faits. Elle ne peut être
                        engagée pour aucun
                        préjudice indirect. La responsabilité de Happytal ne peut non plus être engagée du fait de la
                        mauvaise
                        utilisation de la Plateforme Happydom ou de toute faute d’un Référent. Elle ne saurait pas plus
                        être engagée à
                        raison de faits imputables à un tiers ou à un évènement de force majeure.<br/><br/>

                        Happytal fera ses meilleurs efforts pour sécuriser l'accès à la Plateforme Happydom, mais ne
                        saurait lui assurer
                        une sécurité absolue. Happytal ne saurait être tenue responsable en raison des
                        dysfonctionnements ou
                        dommages inhérents à l’usage du réseau Internet, tels que rupture de service, intrusion
                        extérieure ou présence
                        de virus informatiques transmis via le réseau, ou des atteintes à la sécurité informatique
                        pouvant causer des
                        dommages aux matériels informatiques et aux données des Référents. Le Référent reconnait en
                        outre que
                        Happytal ne peut être tenue responsable à son égard pour tout dommage indirect, résultant de son
                        utilisation
                        de la Plateforme Happydom (et notamment perte de données, opportunités, réputation) ou de son
                        impossibilité d'accéder à celle-ci, de son utilisation des éléments contenus sur la Plateforme
                        Happydom,
                        notamment des contenus postés par d'autres Référents, de toute défaillance, erreur ou
                        interruption de la
                        Plateforme Happydom, ou bien de tout virus informatique.<br/><br/>

                        Par ailleurs, Happytal ne saurait être responsable des conséquences résultant de la violation
                        des présentes CGU
                        par le Référent. A cet égard, le Référent s'engage à indemniser Happytal en cas d'action engagée
                        par des tiers,
                        ou de condamnation de cette dernière, résultant de faits imputables au Référent.<br/><br/>

                        En outre, conformément à l'article 6 de la LCEN, Happytal ne saurait voir sa responsabilité
                        engagée à raison des
                        contenus publiés sur la Plateforme Happydom par des Référents, Etablissements ou Vendeurs, sauf
                        si elle ne
                        les rendait pas promptement inaccessibles après avoir été informée de leur illicéité dans le
                        cadre de la
                        procédure de notification prévue par cette disposition.
                    </div>
                    <h2 id="h220">10. Accessibilité à la Plateforme Happydom</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal fera ses meilleurs efforts pour que la Plateforme Happydom fonctionne en permanence
                        sans
                        interruption, sous réserve des périodes de maintenance, programmée ou non, et des éventuels
                        incidents
                        techniques pouvant rendre l’accès à la Plateforme Happydom impossible ou limitée.<br/><br/>

                        En cas d'incident ou de difficulté d'accès au Compte ou la Plateforme Happydom, Happytal en
                        informera le
                        Référent et fera ses meilleurs efforts pour y remédier dans les meilleurs délais, sans garantie
                        de temps de
                        rétablissement. En cas de nécessité, Happytal se réserve la possibilité de limiter ou de
                        suspendre l’accès à la
                        Plateforme Happydom, pour procéder à toute opération de maintenance et/ou d’amélioration. Dans
                        cette
                        hypothèse, Happytal fera ses meilleurs efforts pour en informer l'ensemble des utilisateurs à
                        l’avance par
                        message général sur la page d’accueil de la Plateforme Happydom.<br/><br/>

                        Dans le cadre de ces opérations de maintenance et/ou d’amélioration, Happytal fera ses meilleurs
                        efforts pour
                        effectuer les sauvegardes des contenus diffusés afin de permettre leur restauration. La
                        responsabilité de
                        Happytal ne saurait toutefois être engagée en cas de perte de contenus au cours des opérations
                        de
                        maintenance et/ou d’amélioration.<br/><br/>

                        Ces obligations de Happytal de faire ses meilleurs efforts sont exclusives de toutes autres, de
                        sorte que
                        Happytal ne saurait être tenu responsable en cas de service partiellement ou totalement
                        inaccessible.
                    </div>
                    <h2 id="h221">11. Modification des CGU</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Happytal se réserve le droit de modifier les présentes CGU à tout moment, en tout ou en partie.
                        En cas de
                        modification des CGU, le Référent en sera informé par email à l'adresse électronique communiquée
                        lors de son
                        inscription. La version modifiée des CGU entrera en vigueur trente (30) jours calendaires à
                        compter de la
                        notification reçue. Le Référent est réputé avoir accepté la modification des CGU s'il n'a pas
                        notifié à Happytal,
                        au plus tard à la date d'entrée en vigueur de la modification, qu'il refuse celle-ci. Si le
                        Référent refuse les
                        modifications apportées aux CGU, il peut résilier les CGU, sans frais, avant la date d'entrée en
                        vigueur proposée
                        des CGU modifiées selon les formes décrites à l'article 12 ci-dessous.<br/><br/>

                        Le Référent pourra par ailleurs identifier la date à laquelle Happytal a procédé aux dernières
                        modifications en
                        se rapportant à la « Dernière date de mise à jour » indiquée ci-dessus.
                    </div>
                    <h2 id="h222">12. Résiliation</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les présentes CGU sont conclues pour une durée indéterminée.<br/><br/>

                        Le Référent peut résilier les CGU à tout moment, sans frais, à partir de son Compte, en cliquant
                        sur l'option
                        « Supprimer mon compte Happydom », ou en adressant un message à l’adresse électronique
                        contact@happytal.com.<br/><br/>

                        En cas de manquement du Référent à ses obligations, Happytal se réserve le droit de suspendre
                        temporairement l’accès à la Plateforme Happydom sous réserve de l'en informer immédiatement par
                        un email
                        exposant les manquements constatés. L’accès à la Plateforme Happydom ne pourra être rétabli
                        qu’après que le
                        Référent a effectivement remédié au(x) manquement(s) en cause. En cas de manquement(s) grave(s)
                        et/ou
                        répété du Référent à ses obligations, ayant déjà donné lieu à une mise en demeure (par email ou
                        sur tout
                        support écrit) de remédier aux dits manquements, Happytal pourra résilier les CGU et supprimer
                        le Compte du
                        Référent, à effet immédiat et sans autre formalité sous réserve de l'en informer immédiatement
                        par email. Le
                        Référent reconnait que cette suspension ou cette résiliation ne lui permet pas de prétendre à
                        une quelconque indemnité.<br/><br/>

                        En cas d’inactivité du Compte pendant une période de dix-huit (18) mois, Happytal adressera un
                        email au
                        Référent afin de lui demander s’il souhaite le maintien de celui-ci. Si le Référent n’a pas
                        notifié à Happytal sa
                        volonté de maintenir le Compte ouvert dans un délai de trente (30) jours calendaires suivant la
                        réception de la
                        demande de Happytal, Happytal pourra résilier les CGU et supprimer le Compte du Référent, à
                        effet immédiat
                        et sans autre formalité que l’envoi d’une confirmation de clôture du Compte par email. Le
                        Référent reconnait
                        que cette résiliation ne lui permet pas de prétendre à une quelconque indemnité.
                    </div>
                    <h2 id="h223">13. Effets de la résiliation</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        En cas de résiliation des CGU, à l'initiative du Référent ou de Happytal, les commandes en cours
                        mais non
                        exécutées seront automatiquement annulées. Le paiement des commandes exécutées devra être
                        effectué par
                        le Référent conformément aux modalités prévues par l'article 4.4 susvisé. Happytal procèdera à
                        la suppression
                        dans ses systèmes d’information des Données de connexion, données personnelles et de toute autre
                        donnée
                        afférente au Référent, à son Compte et au(x) Sous-compte Résident(s), à l’exception des
                        informations qui
                        doivent éventuellement être conservées par Happytal et/ou les Vendeurs au titre de leurs
                        obligations légales.
                    </div>
                    <h2 id="h224">14. Droit applicable et compétence juridictionnelle</h2>
                    <div class="vx-col sm:tw-tw-w-full md:tw-tw-w-full mx-auto self-center d-theme-dark-bg right">
                        Les présentes CGU sont soumises au droit français.<br/><br/>

                        Pour toute question ou difficulté d’ordre technique, administrative ou commerciale, ou pour
                        toute réclamation
                        en lien avec la Plateforme Happydom, merci de contacter contact@happytal.com.<br/><br/>

                        En cas de litige entre le Référent et Happytal en lien avec l'exécution des CGU ou l'utilisation
                        de la Plateforme
                        Happydom, chacune des parties s’efforcera de tenter de régler le litige de façon amiable. En cas
                        de persistance
                        du désaccord, le Référent dispose de la faculté (non de l'obligation) de saisir gratuitement un
                        médiateur.
                        Happytal adhère au système de médiation FEVAD dont les coordonnées sont les suivantes :
                        <a href="mailto:contact@fevad.com" target="_blank">contact@fevad.com</a> / +33 1 42 56 38 86. En
                        cas d'échec d'une éventuelle médiation, l'une ou l'autre partie
                        pourra saisir la juridiction compétente énoncée au présent article.<br/><br/>

                        Tout litige relatif à l'interprétation des CGU et/ou à leur exécution et/ou leur résiliation
                        relève des juridictions
                        compétentes en vertu du droit commun. Conformément à l'article R.631-3 du Code de la
                        consommation, le
                        Référent pourra en tout état de cause saisir, soit l'une des juridictions territorialement
                        compétentes en vertu
                        du Code de procédure civile, soit la juridiction du lieu où il demeurait au moment de
                        l'acceptation des CGU ou
                        de la survenance du fait dommageable.
                    </div>
                </div>
                <footer class="the-footer flex-wrap justify-between">
                    <p>
                        <span>COPYRIGHT &copy;</span>
                        <span>{{ new Date().getFullYear() }} </span>
                        <a href="https://www.happytal.com" target="_blank" rel="nofollow">happytal</a>
                        <span class="hidden sm:tw-inline-block">, Tous droits réservés</span>
                    </p>
                </footer>
            </div>
        </div>
    </div>
</template>
<script>
    import TheNavbarVerticalPublic from '@/components/navbar/TheNavbarVerticalPublic.vue'
    import VNavMenuCgu from '@/components/vertical-nav-menu/VerticalNavMenuCgu.vue'
    import $ from 'jquery'

    export default {
        components: {
            TheNavbarVerticalPublic,
            VNavMenuCgu,
        },
        data() {
            return {
                title: `Conditions Générales d’accès et d’utilisation Happydom`
            }
        },
        computed: {},
        methods: {},
        mounted() {
            $(document).ready(function () {
                $('.menu a').bind('click', function (e) {
                    e.preventDefault()
                    var target = $(this).attr("href")
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top - 80
                    }, 600, function () {
                    })
                    return false
                })
            })
            $(window).scroll(function () {
                var scrollDistance = $(window).scrollTop()
                $('.divscroll h2').each(function (i) {
                    if ($(this).position().top - 80 <= scrollDistance) {
                        $('.menu a.selected').removeClass('selected')
                        $('.menu a').eq(i).addClass('selected')
                    }
                })
            }).scroll()
        }
    }
</script>

<style lang="scss">
    #cgu {
        .vs-sidebar .vs-sidebar--items {
            overflow: hidden;
            overflow: scroll !important;
            height: 100%;
        }

        #content-area {
            margin-top: 100px;
            padding-bottom: 80px;

            .vs-sidebar .vs-sidebar--items {
                overflow: hidden;
                height: 100%;
                position: fixed;
            }

            h2 {
                margin: 40px 0 20px 0;
            }

            .right {
                background: #fff;
                border-radius: .5rem;
                box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
                -webkit-transition: all .3s ease-in-out;
                padding: 30px;
            }

            footer {
                margin-top: 40px;

                p {
                    margin: 0 auto;
                    display: block;
                    text-align: center;
                }

            }
        }
    }
</style>
